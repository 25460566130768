import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/img/shield-sxs-logo.png';
import '../App.css';

const CustomNavbar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleNavigation = (sectionId, event) => {
    event.preventDefault();

    const section = document.getElementById(sectionId);
    if (section) {
      const top =
        section.getBoundingClientRect().top +
        window.scrollY -
        document.querySelector('.custom-navbar').offsetHeight;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
    setActiveLink(sectionId);
  };

  return (
    <Navbar
      expand={false}
      className={`custom-navbar ${scrolled ? 'scrolled' : ''}`} /* Add `scrolled` class based on scroll */
      sticky="top"
    >
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={logo}
            width="100"
            height="100"
            className="d-inline-block align-top logo"
            alt="SxS Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto nav-links"> {/* Use ms-auto to align right */}
            <Nav.Link
              as={Link}
              to="#legacy@SXS"
              className={activeLink === 'legacy@sxs' ? 'active navbar-link' : 'navbar-link legacy-link'}
              onClick={(e) => handleNavigation('legacy@sxs', e)}
            >
              Legacy
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="#work@N2L"
              className={activeLink === 'work@n2l' ? 'active navbar-link' : 'navbar-link work-link'}
              onClick={(e) => handleNavigation('work@n2l', e)}
            >
              Work
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="#play@MP3"
              className={activeLink === 'play@mp3' ? 'active navbar-link' : 'navbar-link play-link'}
              onClick={(e) => handleNavigation('play@mp3', e)}
            >
              Play
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
