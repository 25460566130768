import React from 'react';
import '../App.css';

const NewsTicker = () => {
    return (
        <div className="news-ticker-container-wrapper">
        <div className="news-ticker-container">
            <div className="news-label">News</div>
            <div className="news-ticker">
            <span>@Tony has Created Figma Designs for the Main Mesaverse and SXS charity page. @Tareq has been able to communicate with Dev companies to help with the code. @James negotiates with team for the windows Implementations. @Tony has created and deployed the Main Page for sxs.com and charity page.</span>
            </div>
            </div>



        </div>
    );
};

export default NewsTicker;

