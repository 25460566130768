// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  background: #343a40;
  color: white;
  display: flex;
  //padding: 10px;
  text-align: center;
  justify-content: space-between;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p className="offices-text">Offices : Montreal , Canada - Dubai , UAE - Athens, Greece - Kiev , Ukraine - Valencia , Spain - Shenzhen , China. </p>
      <p class="footer-text">&copy; 2024 Sxs.com - All rights reserved.</p>  
    </FooterContainer>
  );
};

export default Footer;