import React, { useState } from "react";
import { Size48 } from "../../assets/icons/Size48";
import { Size481 } from "../../assets/icons/Size481";
import { useInView } from "react-intersection-observer";
import "../../App.css";

const slides = [
  {
    title: "WHO:",
    content: (
      <>
      The Mesaverse and its inhabitants have come together, achieving this in accordance with their individual standings.
      <ol>
      <li>Fundraisers and Donors at SXS</li>
      <li>Allies and Ally Icons at N2L</li>
      <li>Producers and Executive Producers at MP3</li>
      <li>Shareholders of Ecosystem Realms </li>
      </ol>
      Which one are you?
      </>
    ),
  },
  {
    title: "WHAT:",
    content: (
      <>
       Prepare to be captivated by the ultimate fusion of purpose, passion, and perpetuity as we venture into the unknown realms
        of the Mesaverse. This extraordinary journey will challenge your perceptions, ignite your creativity, and unveil the 
        remarkable synergy between Work, Play, and Legacy.

      </>
    ),
  },
  {
    title: "WHEN:",
    content: (
      <>
    The time is now! Seize the opportunity to be among the pioneering explorers who will shape the future of the Mesaverse. 
    Apply to be and Ally, Citizen or Producer to embark on this odyssey, where the boundaries of convention are pushed, and the possibilities are limitless.
      </>
    ),
  },
  {
    title: "WHY:",
    content: (
      <>
In an era where success is measured not just by profits but by the profound impact we leave on the world, the Mesaverse encourages us to redefine the very 
essence of our pursuits. Here, we will forge a new paradigm, where Work, Play, and Legacy intertwine seamlessly.      </>
    ),
  },
  {
    title: "HOW:",
    content: (
      <>
We plan to build the Mesaverse with the help of of our Allies, Producers and Citizens. Our secret weapon: 100% open transparency in all we do, including all financial transactions.      </>
    ),
  },
  {
    title: "WHERE:",
    content: (
      <>
        The destination is the Mesaverse, where all are invited!
        <br>
        </br>
        Our global community is uniting as contributors, engaging in charitable acts as SXS Fundraisers and Donors to assist those in need, collaborators known as N2L Allies , advancing the continuously expanding digital landscape, and, of course, our MP3 Producers who are crafting our artistic media content.
      </>
    )
  }
];

export const Box = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div ref={ref} className={`container ${inView ? 'animate__animated animate__fadeInDown' : ''} mt-5`}>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow-sm p-4 rounded-3" style={{ backgroundColor: '#D9D9D9' }}>
            <div className="row">
              {/* Left side with titles */}
              <div className="col-4 box-wording">
                <h2 className={`box-title font-amazon-thin ${inView ? 'animate__animated animate__slideInLeft' : ''}`}>{slides[currentSlide].title}</h2>
              {/* </div> */}
              <p className={`card-text font-amazon-regular ${inView ? 'animate__animated animate__slideInUp' : ''}`}>
                  {slides[currentSlide].content}
                </p>
              {/* Right side with content */}
              </div>
            </div>

            <div className="d-flex justify-content-between box-arrows">
              <button className="btn btn-link p-0" onClick={prevSlide}>
                <Size48 className={`box-left-arrow`}/>
              </button>
              <button className="btn btn-link p-0 box-right-arrow" onClick={nextSlide}>
                <Size481 className={`box-right-arrow`}/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
