import React from 'react';
import styles from './newsTicker.module.css'; // Make sure to create and use this CSS module for styling

const NewsTicker = () => {
  return (
    <div className={styles.newsTickerContainer}>
      <span className={styles.newsText}>News :</span>
    </div>
  );
}

export default NewsTicker;