import React from 'react';
import { useInView } from 'react-intersection-observer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './index.css';

export const SxSummary = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.8,
    });

    return (
        <div
            ref={ref}
            className={`sxs-container container ${inView ? 'animate__animated animate__fadeIn' : ''} mt-5`}
            id="legacy@sxs"
        >
            <div className="row align-items-center">
                
                <div className="col-12 col-lg-9 order-2 order-lg-1">
                <Link className='sxsLink' to="/Legacy" target="_blank" rel="noopener noreferrer">
                    <span
                        className={`sxs-analytical-success font-amazon-bold-italic ${inView ? 'animate__animated animate__fadeInDown' : ''}`}
                    >
                       It's your Legacy - We Just Accommodate
                    </span>
                    </Link>
                    <div className="legacy-accomodate">
                        <span
                            className={`ultimate-online-platform font-amazon-regular ${inView ? 'animate__animated animate__slideInUp' : ''}`}
                        >
                            The first-ever self-sustaining philanthropic organization globally, 
                            leveraging the capabilities of the Mesaverses Citizens ranked Fundraisers, 
                            Donors, Allies, Producers, 
                            and AI to meet its annual billion-dollar target as a worldwide public entity.
                            <br />
                            <br />
                            In essence, it serves as the premier online platform for charitable contributions, 
                            generating long-term funding for SxS and its associated causes on a global scale.
                            <br />
                            <br />
                            While at SxS, we continue to disseminate information, motivate individuals,
                            and cultivate connections between donors and our meticulously chosen charities, 
                            continuasly enhancing the accessibility of advanced technology and collaboration more accessible in the charitable field.
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-3 text-center order-1 order-lg-2">
                    <Link to="/Legacy" target="_blank" rel="noopener noreferrer">
                        <div
                            className={`white-logo ${inView ? 'animate__animated animate__slideInRight' : ''}`}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};