// src/App.js
import React , {useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes ,useLocation } from 'react-router-dom'; // Update imports
import Navbar from './components/Navbar';
import SliderComponent from './components/Slider';
import LoginInfo from './components/LoginInfo';
import NewsTicker from './components/NewsTicker';
import Footer from './components/Footer';
import { Box } from './components/Box/index';
import { CallToArms } from './components/CallToArms/CallToArms';
import { SxSummary } from './components/SxSummary/SxSummary';
import { N2lSummary } from './components/N2lSummary/N2lSummary';
import { Mp3Summary } from './components/Mp3Summary/Mp3Summary'; // Ensure correct import
import CharityPage from './components/CharityPage/Main'; // New component for the charity page
import ReactGA from 'react-ga4';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => (
  
  <>
    <Navbar />
    <NewsTicker />
    <SliderComponent />
    <LoginInfo />
    <Box />
    <CallToArms />
    <SxSummary />
    <N2lSummary />
    <Mp3Summary />
    <Footer />
  </>
);

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

const App = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  return (
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Legacy" element={<CharityPage />} />
      </Routes>
    </Router>
  );
};

export default App;