// src/components/Mp3Summary/Mp3Summary.js
import React from 'react';
import { useInView } from 'react-intersection-observer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';  // Ensure this imports the correct CSS file for Mp3Summary

export const Mp3Summary = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.8
    });

    return (
        <div ref={ref} className={`mp3-container container ${inView ? 'animate__animated animate__fadeIn' : ''} mt-5`} id='play@mp3'>
            <div className="row align-items-center">
                <div className={`col-12 col-md-3 text-center mp3-logo-transparent ${inView ? 'animate__animated animate__slideInLeft' : ''}`} />
                <div className="col-12 col-md-9">
                    <h2 className={`mp3-slogan font-amazon-bold-italic ${inView ? 'animate__animated animate__slideInLeft': ''}`}>Where Artistic Talents , Come to Play</h2>
                    <p className={`mp3-ecosystem font-amazon-regular ${inView ? 'animate__animated animate__slideInUp' : ''}`}>
                    Should your interest lie in engaging with or producing art, our vibrant ecosystem, MP3,
                     is specifically crafted to unlock the creative potential of individuals. 
                     It provides a platform for showcasing talents, expressing passion for the arts, 
                     and making meaningful contributions to the cultural landscape.
                        <br />
                        <br />
                        The MP3 Eco aims to create a flourishing economic environment for its Vendees and all its Producers,
                         enabling them to enjoy premium experiences in food, art, and entertainment. The platform is 
                         specifically designed for its distinguished creators, allowing those with producer status to 
                         showcase their skills and creativity while attaining financial prosperity within the mesaverse.
                    </p>
                </div>
            </div>
        </div>
    );
}