/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Size48 = ({ className , onClick}) => {
  return (
    <svg
      className={`size-48 ${className}`}
      fill="none"
      height="110"
      viewBox="0 0 110 110"
      width="110"
      onClick= {onClick}
      style = {{cursor:"pointer"}}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M68.75 82.5L41.25 55L68.75 27.5"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  );
};
