// src/components/N2lSummary/N2lSummary.js
import React from 'react';
import { useInView } from 'react-intersection-observer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';  // Ensure this imports the correct CSS file for N2lSummary

export const N2lSummary = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.8
    });

    return (
        <div ref={ref} className={`n2l-container container ${inView ? 'animate__animated animate__fadeIn' : ''} mt-5`} id='work@n2l'>
            <div className="row align-items-center">
                
                {/* Link wrapping the logo to open in a new tab */}
                <a href="https://n2l.com" target="_blank" rel="noopener noreferrer" className="col-12 col-md-3 text-center n2l-logo-transparent">
                    <div className={`${inView ? 'animate__animated animate__slideInLeft' : ''}`} />
                </a>

                {/* Text content */}
                <div className="col-12 col-md-9">
                    <a className='n2lLink' href="https://n2l.com" target="_blank" rel="noopener noreferrer">
                        <h2 className={`n2l-slogan font-amazon-bold-italic ${inView ? 'animate__animated animate__slideInLeft' : ''}`}>
                            Successful Business, Works Τwo Ways
                        </h2>
                    </a>
                    <p className={`n2l-ecosystem font-amazon-regular ${inView ? 'animate__animated animate__slideInUp' : ''}`}>
                    The N2L Ecosystem represents more than just slogans and symbols; 
                    it embodies a comprehensive framework of values and principles that drive our Allie-ranked 
                    citizens toward achieving excellence in their respective domains. This framework also enables the Patrons, 
                    who are the clients of these products and services, to reap significant benefits.
                        <br />
                        <br />
                        N2L aims to create a financial environment where both Patrons and Allies can depend on a brand dedicated to providing exceptional quality in its offerings.
                        
                        <br/>
                        <br/>
                        Through this initiative, Allies will have the chance to unite on a distinctive platform designed to showcase their skills and expertise, 
                        while also securing substantial, transformative income.
                    </p>
                </div>
            </div>
        </div>
    );
}
