// Main.js
import React from 'react';
import CustomNavbar from './Navbar';
import HeroImageSlider from './HeroImageSlider';
import NewsTicker from './newsTicker';
import AboutSection from './AboutSection';
import WaysToTakePart from './WaysToTakePart';
import AnalyticalSuccess from './AnalyticalSuccess';
// import CauseSupportDistribution from './CauseSupportDistribution';
export default function Main() {
  return (
    <div>
      <CustomNavbar />
      <HeroImageSlider />
      <NewsTicker />
      <AboutSection />
      <WaysToTakePart />
      <AnalyticalSuccess />
      {/* <CauseSupportDistribution /> */}
      {/* Other components can go here */}
    </div>
  );
}