import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import '../App.css';

const LoginInfo = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  return (
    <Container
      className={`info-container login-info-box ${inView ? 'animate__animated animate__slideInDown' : ''}`}
      fluid
      ref={ref}
    >
      <Row className="justify-content-center align-items-center no-gutters">
        <Col md={6}>
          <Form id="login-form" className="login-form d-flex justify-content-end align-items-center">
            <Form.Group controlId="formEmail" className="mr-2 mb-0 flex-grow-1">
              <Form.Control type="email" placeholder="Email" required />
            </Form.Group>
            <Form.Group controlId="formPassword" className="mr-2 mb-0 flex-grow-1">
              <Form.Control type="password" placeholder="Password" required />
            </Form.Group>
            <Button variant="primary" type="submit" className="login-button">
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginInfo;