import React from 'react';
import { useInView } from 'react-intersection-observer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import img1 from '../../assets/img/shield-sxs-logo.png';

export const CallToArms = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <div ref={ref} className={`call-to-arms-section ${inView ? 'animate__animated animate__fadeIn' : ''} mt-5`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-3 text-center">
                        <img src={img1} alt="Shield" className={`img-fluid ${inView ? 'animate__animated animate__zoomIn' : ''} shield-img`} />
                    </div>
                    <div className="col-12 col-md-9">
                        <h1 className={`call-to-arms font-amazon-bold-italic ${inView ? 'animate__animated animate__slideInLeft' : ''}`} style={{ color: '#84582F' }}>Call To Arms</h1>
                        <p className={`business-landscape-success font-amazon-italic ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                        In the contemporary digital landscape, success is often evaluated exclusively through profit margins and market share. However, we believe it is essential to adopt a more comprehensive perspective that encompasses the three fundamental aspects of life: Work, Play, and Legacy.
                        </p>
                    </div>
                </div>
                <div className="frame" />
            </div>
        </div>
    );
}