import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import './AboutSection.module.css';
import img1 from '../../assets/charityPageAssets/futuristic-landscape.png';
import aiImage from '../../assets/charityPageAssets/ai-section-image.png';
import brownRectangle from '../../assets/charityPageAssets/brown-rectangle.png';
import greyRectangle from '../../assets/charityPageAssets/grey-rectangle.png';
import yellowRectangle from '../../assets/charityPageAssets/yellow-rectangle.png'; 
import { useInView } from 'react-intersection-observer';
import styles from './AboutSection.module.css';

const AboutSection = () => {
  const [legacyRef, legacyInView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const [successRef, successInView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const [aiRef, aiInView] = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <div className="container my-5 position-relative">  
      <div className="row mb-5" ref={legacyRef}>
        <div className={`col-lg-6 ${legacyInView ? 'animate__animated animate__fadeInDown visible' : 'hidden'}`}>
          <h2>Legacy @ SxS</h2>
          <p className={legacyInView ? 'animate__animated animate__fadeInLeft animate__delay-1s visible' : 'hidden'}>
            Your legacy @ SxS™ Legacy encapsulates the enduring impact we aspire to
            live in the world. It encompasses the values we impart to future generations,
            the positive influence we exert on others, and the contributions that outlast our time.
            Building a legacy isn't just about personal achievements; it's about fostering a culture
            of purpose-driven action, social responsibility, and sustainable growth that resonates
            beyond individual lifetimes. Members at SxS are called “Citizens”.
          </p>
        </div>
        <div className={`col-lg-6 ${legacyInView ? 'animate__animated animate__fadeInRight animate__delay-2s visible' : 'hidden'}`}>
          <img src={img1} alt="Legacy" className="img-fluid rounded" />
        </div>
      </div>

      <div className="row mb-5 position-relative" ref={successRef}>
         {/* Grey rectangle as the background for Success section */}
         <img src={greyRectangle} alt="Grey Rectangle" className="bg-grey-rectangle" />
        <div className={`col-lg-6 order-lg-2 ${successInView ? 'animate__animated animate__fadeInDown visible' : 'hidden'} ${styles.successAtSxS}`}>
          <h2>Success @ SxS</h2>
          <p className={successInView ? 'animate__animated animate__fadeInRight animate__delay-1s visible' : 'hidden'}>
            The notion of a Mesaverse nestled within our Universe captivates the imagination,
            hinting at a vast tapestry of interconnected ecosystems that lay the groundwork
            for boundless realms. This concept invites exploration into the intricate web
            of environments coexisting within a larger framework, sparking curiosity about the
            diversity and potential nestled within these realms.
          </p>
        </div>
        <div className={`col-lg-6 order-lg-1 ${successInView ? 'animate__animated animate__fadeInLeft animate__delay-2s visible' : 'hidden'}`}>
          <img src={brownRectangle} alt="Success" className="img-fluid rounded" />
          <p className={ `${successInView ? 'animate__animated animate__fadeInLeft animate__delay-3s visible' : 'hidden'} ${styles.successAtSxSDwelve}`}>
          As we delve into the complexities of the SxS™ Mesaverse and its ecosystems, we're reminded of the
          profound beauty and interconnectedness shared among like-minded individuals. It fosters an
          appreciation for the interplay of all things and encourages us to approach the unknown with a spirit
          of curiosity and wonder.
          </p>
        </div>
      </div>

      <div className="row mb-5" ref={aiRef}>
        <div className={`col-lg-6 ${aiInView ? 'animate__animated animate__fadeInDown visible' : 'hidden'}`}>
          <h2>AI @ SxS</h2>
          <p className={aiInView ? 'animate__animated animate__fadeInLeft animate__delay-1s visible' : 'hidden'}>
            At the heart of this exploration lies the groundbreaking endeavour of SxS™—the world's
            inaugural fully revenue-generating transparent Public Organization steered by artificial
            intelligence (A.I.). This innovative approach marries technological prowess with the principles
            of transparency and accountability, ensuring that every donation or dollar invested yields maximal impact.
          </p>
        </div>
        <div className={`col-lg-6 ${aiInView ? 'animate__animated animate__fadeInRight animate__delay-2s visible' : 'hidden'}`}>
          <img src={aiImage} alt="AI" className="img-fluid rounded" />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
