import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import {Fade} from 'react-slideshow-image'
import img1 from '../assets/img/sag-a-wallpaper.jpg'
import img2 from '../assets/img/SxS-charity-foundation.jpeg'
import img3 from '../assets/img/N2l.jpeg'
import img4 from '../assets/img/Mp3-image.jpeg'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fonts.css';
const slideImages = [
  {
    url: img1 ,
    title: "Welcome To The Mesaverse",
    caption1: "The opposite of above, about, or beyond, is inside and within.",
    caption2: "The Greek word for this is <mesa> . Hence, a mesaverse is a world that is within the world.",
    titleStyle: {color: '#004c4c' , fontSize: '40px'},
    caption1Style: {color: 'grey' , fontSize: '30px'},
    caption2Style: {color:'black' , fontSize: '24px'}
  },
  {
    url:img4,
    title: "SxS.com",
    caption1: "It's your legacy , we Just Accomodate",
    caption2: "The ultimate online platform for raising generational funds for SxS Causes Worldwide",
    titleStyle: { color: '#84582F', fontSize: '40px' }, // Customize as needed
    caption1Style: {color: 'grey' , fontSize: '30px'},
    caption2Style: {color: 'black' , fontsize: '24px'}
  },
  {
    url:img3,
    title: "N2L.com",
    caption1: "Never too Late",
    caption2: "Harnessing the power of knowledge, networking, and innovation, N²L empowers its allies to forge a path toward success.",
    titleStyle: {color: "#008080" , fontsize: '40px'},
    caption1Style: {color: 'grey' , fontSize: '30px'},
    caption2Style: {color: 'black' , fontSize: '24px'}
  },
  {
    url:img2,
    title:"Mp3.net",
    caption1: "Militant Play",
    caption2: "Mp³ provide a sanctuary for musicians, artists, filmmakers, writers, and creatives of all kinds to flourish and leave a lasting mark and drives their commercial success.",
    titleStyle: {color: "#FF8000" , fontSize: '40px'},
    caption1Style: {color: ' grey' , fontSize: '30px'},
    caption2Style: {color: 'black' , fontSize:'24px'}
  }


]

const divStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '600px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  textAlign: 'center',
  color: 'white',
  padding: '20px',
}

const captionStyle = {
  fontSize: "20px",
  maxWidth: '800px',
  background: '#',
  color: '#000000'
}

const h2Style = {
  fontsize:"50px",
  fontWeight:'bold',
  margin : '0 0 20px 0',
  background:"#",
  color:"white"
}

const textContainerStyle = {
  backgroundColor: 'rgba(217, 217, 217, 0.8)',
  borderRadius:'15px',
  padding:'20px',
  maxWidth: '800px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
};


function ImageSlider() {
  return (
    <div className='slide-container'>
      <Fade duration={6000}  autoplay={false} transitionduration={500}>
        {slideImages.map((image,index) => (
          <div key={index}>
            <div style={{...divStyle , backgroundImage:`url(${image.url})`}}>
              <div style={textContainerStyle} className='text-center text-white'>
              <h2 style={{...h2Style , ...image.titleStyle}} className="animate__animated animate__fadeInDown font-amazon-bold">
                  {image.title}
              </h2>
              <p style={{...captionStyle , ...image.caption1Style}} className="animate__animated animate__fadeInUp font-amazon-italic">
              {image.caption1}</p>
              <p style={{...captionStyle , ...image.caption2Style}} className="animate__animated animate__fadeInUp font-amazon-regular">
                {image.caption2}
              </p>
            </div>
          </div>
          </div>
        ))}
      </Fade>
      </div>
      )
    }

  





export default ImageSlider;