import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from '../../assets/img/sxs-logo-transparent.png';

const CustomNavbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand={false}
      className={`${styles.navbar} ${scrolled ? styles.scrolled : ''} py-3`} // Apply the `scrolled` class when needed
    >
      <Navbar.Brand href="#">
        <img
          src={logo}
          width="100%"
          height="58px"
          className={`d-inline-block align-top ${styles.sxsAnalyticalLogo}`}
          alt="SxS Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className={styles.navbarCollapse}>
        <Nav className={styles.nav}>
          <Nav.Link as={Link} to="/" className={styles.navLink}>Home</Nav.Link>
          <Nav.Link href="#" className={styles.navLink}>About SxS</Nav.Link>
          <Nav.Link href="#" className={styles.navLink}>Ways to take part</Nav.Link>
          <Nav.Link href="#" className={styles.navLink}>Analytical success</Nav.Link>
          <Nav.Link href="#" className={styles.navLink}>Support a Cause</Nav.Link>
          <Nav.Link href="#" className={styles.navLink}>Charity gifts</Nav.Link>
          <Nav.Link href="#" className={styles.navLink}>Connect with us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
