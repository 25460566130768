import React from 'react';
import heroImage from '../../assets/img/Mp3-image.jpeg'; // Update the path if necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './HeroImageSlider.module.css'; // Make sure to create and use this CSS module for styling

const HeroImageSlider = () => {
  return (
    <div className={`${styles.heroContainer} slide-container`}>
      <div className={styles.heroImage} style={{ backgroundImage: `url(${heroImage})` }}>
        <div className={`${styles.textContainer} text-center text-white`}>
          <h2 className="animate__animated animate__fadeInDown">
            <span style={{ color: 'black', fontSize: '50px'}}>IT’S YOUR</span> <span style={{ color: '#C17575' , fontSize: '50px'}}>LEGACY</span>
          </h2>
          <p className="animate__animated animate__fadeInUp" style={{ color: 'black', fontSize: '35px' }}>
            We just accomodate.
          </p>
          <p className="animate__animated animate__fadeInUp" style={{ color: 'black', fontSize: '20px' , }}>
            The ultimate online platform for giving, raising and generating funds for SxS™ Charities Worldwide.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroImageSlider;