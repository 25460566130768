import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import styles from './AnalyticalSuccess.module.css';
import analysisImage from '../../assets/charityPageAssets/analytical-success.png';
import { useInView } from 'react-intersection-observer';




const AnalyticalSuccess = () => {

  const {ref , inView} = useInView({
    triggerOnce: true ,
    threshold: 0.5,
  })

    return (
        <div ref={ref} className={`container my-5 p-5 ${styles.analyticalSuccess}`}>
            <div className='row'>
                <div className='col-lg-6'>
                    <h2 className={`${styles.heading} ${inView ? 'animate__animated animate__fadeInDown' : ''}`}>
                        Analytical Success
                    </h2>
                    <h4 className={`${styles.subheading} ${inView ? 'animate__animated animate__fadeInDown__delay-1s' :'' }`}>
                        Tracking our Progress and Impact
                    </h4>
                    <p className={`${styles.analyticalSuccessParagraph} ${inView ? 'animate__animated animate__fadeInLeft animate__delay-2s': ''}`}>
            At SxS, we prioritize transparency and accountability by closely monitoring our progress
            and impact through detailed analytics.
          </p>
          <p className={`${styles.analyticalSuccessParagraph} ${inView ? 'animate__animated animate__fadeInLeft animate__delay-3s' : ''}`}>
            Our analytical success metrics help us understand our community's growth, optimize our initiatives,
            and ensure that we are meeting our objectives.
          </p>
          <p className={`${styles.analyticalSuccessParagraph} ${inView ? 'animate__animated animate__fadeInLeft animate__delay-4s' : ''}`}>
            By sharing these insights, we aim to build trust and continuously improve our efforts.
          </p>
        </div>
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <img src={analysisImage} alt="Analysis" className={`${styles.analysisImage} ${inView ? 'animate__animated animate__fadeInRight animate__delay-5s' : ''}`}/>
        </div>
        </div>
        </div>
    )
}

export default AnalyticalSuccess;
