import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import styles from './WaysToTakePart.module.css';
import inviteIcon from '../../assets/charityPageAssets/invite-icon.png'; 
import citizenshipIcon from '../../assets/charityPageAssets/citizenshipIcon.png';
import donateIcon from '../../assets/charityPageAssets/donate-icon.png';
import corporateGivingIcon from '../../assets/charityPageAssets/corporate-giving.png';
import securitiesIcon from '../../assets/charityPageAssets/donate-securities.png';
import fundraiseIcon from '../../assets/charityPageAssets/fundraise-icon.png';

const WaysToTakePart = () => {
  const cardsData = [
    {
      icon: inviteIcon,
      title: "Get invited - Free account",
      description: [
        "Access to Basic Features: Get a glimpse of what SxS has to offer.",
        "Community Updates: Stay informed with regular news and updates.",
        "Exclusive Invitations: Receive your invitation to join the vibrant SxS community."
      ],
      buttonText: "Get Now",
    },
    {
      icon: citizenshipIcon,
      title: "Apply For SxS Citizenship (Monthly)",
      description: [
        "Exclusive Content: Gain access to newsletters, blogs, and specialized data.",
        "Event Participation: Attend local and global events to connect with other members.",
        "Enhanced Profile: Create a comprehensive profile to showcase your skills and interests.",
        "Community Engagement: Participate in discussions, contribute to community projects, and collaborate with like-minded individuals."
      ],
      buttonText: "Apply Now",
    },
    {
      icon: donateIcon,
      title: "Donate Once",
      description: [
        "Immediate Impact: Your donation directly supports ongoing projects and initiatives.",
        "Community Support: Help us continue to provide valuable resources and services to our members.",
        "Transparency: See the tangible effects of your contribution through updates and reports."
      ],
      buttonText: "Donate Now",
    },
    {
      icon: corporateGivingIcon,
      title: "Corporate Giving",
      description: [
        "Brand Visibility: Gain recognition through event sponsorships and community projects.",
        "Corporate Social Responsibility: Demonstrate your company’s commitment to social good and community development.",
        "Networking Opportunities: Connect with other corporate partners and community leaders.",
        "Exclusive Updates: Receive detailed reports and updates on the impact of your contributions."
      ],
      buttonText: "Learn More",
    },
    {
      icon: securitiesIcon,
      title: "Donate Securities",
      description: [
        "Impactful Contribution: Directly support SxS programs and initiatives that drive community growth.",
        "Efficient Giving: Donate securities easily and securely, with assistance from our dedicated team.",
        "Legacy Building: Make a lasting impact on the community and be recognized for your generous support.",
        "Transparent Reporting: Stay informed about how your donation is being utilized through detailed reports and updates."
      ],
      buttonText: "Donate Securities",
    },
    {
      icon: fundraiseIcon,
      title: "Fundraise",
      description: [
        "Community Engagement: Rally your friends, family, and colleagues to support a cause you care about.",
        "Personal Impact: See the direct results of your fundraising efforts and the difference they make.",
        "Creative Freedom: Organize a fundraising event that aligns with your interests and strengths.",
        "Support and Resources: Access our fundraising toolkit and receive guidance from our team."
      ],
      buttonText: "Start Fundraising",
    }
  ];

  return (
    <div className="container my-5">
      <h2 className="text-center mb-5">Ways To Take Part</h2>
      <div className="row">
        {cardsData.map((card, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className={`card h-100 text-center p-3 animate__animated animate__fadeInUp ${styles.cardCustom}`}>
              <div className={`card-img-top mx-auto ${styles.iconWrapper}`}>
                <img src={card.icon} alt={card.title} className={`img-fluid ${styles.cardIcon}`} />
              </div>
              <div className="card-body">
                <h5 className="card-title">{card.title}</h5>
                <ul className="list-unstyled">
                  {card.description.map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
              </div>
              <div className="card-footer">
                <button className={`btn btn-primary ${styles.customButton}`}>{card.buttonText}</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WaysToTakePart;
